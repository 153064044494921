import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title = 'TuS Jägersfreude',
  description = 'Willkommen beim TuS Jägersfreude - Ihr Sportverein in Saarbrücken für Fußball, Turnen und mehr.',
  keywords = 'TuS Jägersfreude, Sportverein, Saarbrücken, Fußball, Turnen',
  image = '/logo.png', // Pfad zu Ihrem Logo
  url = 'https://www.tus-jaegersfreude.de' // Ihre Domain
}) => {
  const siteTitle = title === 'TuS Jägersfreude' ? title : `${title} | TuS Jägersfreude`;

  return (
    <Helmet>
      {/* Grundlegende Meta-Tags */}
      <title>{siteTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* Open Graph Meta-Tags für soziale Medien */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="TuS Jägersfreude" />

      {/* Twitter Card Meta-Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Weitere wichtige Meta-Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="de" />
      <meta name="geo.region" content="DE-SL" />
      <meta name="geo.placename" content="Saarbrücken" />
    </Helmet>
  );
};

export default SEO;
