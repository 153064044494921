import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Akzeptieren"
      declineButtonText="Ablehnen"
      enableDeclineButton
      style={{ 
        background: "#2B373B",
        fontSize: "14px",
        padding: "1rem",
        zIndex: 9999
      }}
      buttonStyle={{ 
        background: "#4CAF50",
        color: "white",
        fontSize: "14px",
        padding: "8px 16px",
        borderRadius: "4px",
        margin: "10px"
      }}
      declineButtonStyle={{
        background: "#f44336",
        color: "white",
        fontSize: "14px",
        padding: "8px 16px",
        borderRadius: "4px",
        margin: "10px"
      }}
      expires={365}
      onAccept={() => {
        // Hier können Sie Analytics oder andere Cookies aktivieren
        console.log("Cookies akzeptiert");
      }}
      onDecline={() => {
        // Hier können Sie sicherstellen, dass keine optionalen Cookies gesetzt werden
        console.log("Cookies abgelehnt");
      }}
    >
      Diese Website verwendet Cookies, um Ihnen die bestmögliche Funktionalität bieten zu können.{" "}
      <a 
        href="/#/datenschutz" 
        style={{ 
          color: "#ffd700",
          textDecoration: "underline"
        }}
      >
        Mehr erfahren
      </a>
    </CookieConsent>
  );
};

export default CookieBanner;
