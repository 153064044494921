/**
 * Globale Konfigurationseinstellungen für die TuS Jägersfreude Website
 */
import { useState, useEffect } from 'react';

const defaultConfig = {
    enablePasswordProtection: false,
    password: 'tusfreude'
};

// Erstelle einen Store für die Konfiguration
const configStore = {
    ...defaultConfig,
    listeners: new Set(),
    
    // Methode zum Aktualisieren der Konfiguration
    update(newConfig) {
        Object.assign(this, newConfig);
        this.notifyListeners();
    },
    
    // Methode zum Registrieren von Listenern
    subscribe(listener) {
        this.listeners.add(listener);
        return () => this.listeners.delete(listener);
    },
    
    // Benachrichtigt alle Listener über Änderungen
    notifyListeners() {
        this.listeners.forEach(listener => listener(this));
    }
};

// Hook zum Verwenden der Konfiguration in React-Komponenten
export function useConfig() {
    const [config, setConfig] = useState(configStore);
    
    useEffect(() => {
        return configStore.subscribe(() => setConfig({...configStore}));
    }, []);
    
    return config;
}

// Lade die Konfiguration aus der config.json
fetch('/config.json')
    .then(response => response.json())
    .then(jsonConfig => {
        configStore.update(jsonConfig);
    })
    .catch(error => {
        console.error('Fehler beim Laden der Konfiguration:', error);
    });

export default configStore;
