import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import config from '../config';

const LoginScreen = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === config.password) {
      onLogin();
    } else {
      setError(true);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div className="p-4 border rounded shadow-sm" style={{ maxWidth: '400px', width: '100%' }}>
        <h2 className="text-center mb-4">TuS Jägersfreude</h2>
        <p className="text-center mb-4">Diese Website befindet sich im Aufbau</p>
        {error && (
          <Alert variant="danger">
            Falsches Passwort
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="password"
              placeholder="Passwort eingeben"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Anmelden
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default LoginScreen;
