import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Konvertiere den gesamten Inhalt des Paragraphen in einen String
      const textContent = node.content
        .map(content => {
          if (content.marks && content.marks.some(mark => mark.type === 'bold')) {
            return `<strong>${content.value}</strong>`;
          }
          return content.value;
        })
        .join('');

      // Teile den Text an Zeilenumbrüchen und rendere jede Zeile
      return (
        <p className="text-center">
          {textContent.split('\n').map((text, i, array) => (
            <React.Fragment key={i}>
              {/* Ersetze <strong> Tags durch echte React-Komponenten */}
              {text.includes('<strong>') ? (
                <span dangerouslySetInnerHTML={{ __html: text }} />
              ) : (
                text
              )}
              {/* Füge Zeilenumbruch hinzu, außer bei der letzten Zeile */}
              {i < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className="mt-4 mb-3 text-center">{children}</h4>;
    }
  }
};

const Impressum = () => {
  const [impressumContent, setImpressumContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'impressum',
          order: 'sys.createdAt'
        });
        
        setImpressumContent(response.items);
      } catch (error) {
        console.error('Error fetching impressum content:', error);
      }
    };

    fetchContent();
  }, []);

  return (
    <Container className="my-4">
      <h2 className="mb-4 text-center">Impressum</h2>
      
      {impressumContent.map((content) => (
        <Card key={content.sys.id} className="shadow-sm mb-4">
          <Card.Body className="text-center">
            <h3 className="mb-3 text-center">{content.fields.titel}</h3>
            {content.fields.willkommen && 
              <div className="text-center">
                {documentToReactComponents(content.fields.willkommen, renderOptions)}
              </div>
            }
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default Impressum;
