import React, { useState, useEffect } from 'react';
import client from '../config/contentful';
import './Header.css';

const Header = () => {
  const [content, setContent] = useState({
    headline: '',
    logo: null
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const [headlineResponse, logoResponse] = await Promise.all([
          client.getEntries({
            content_type: 'headline',
            limit: 1
          }),
          client.getEntries({
            content_type: 'headlogo',
            limit: 1
          })
        ]);

        setContent({
          headline: headlineResponse.items[0]?.fields.headline || '',
          logo: logoResponse.items[0]?.fields.headlogo || null
        });
      } catch (error) {
        console.error('Error fetching header content:', error);
      }
    };

    fetchContent();
  }, []);

  const getImageUrl = (url) => url?.startsWith('//') ? `https:${url}` : url;

  return (
    <header className="header">
      <div className="logo-wrapper">
        {content.logo && (
          <img
            src={getImageUrl(content.logo.fields.file.url)}
            alt="TuS Jägersfreude Logo"
            className="logo"
          />
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="activities-banner bg-success text-white py-2">
              <h4 className="mb-0 text-center fw-normal">{content.headline}</h4>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
