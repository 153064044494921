/**
 * Contentful Client Konfiguration
 * 
 * Diese Datei konfiguriert den Contentful Client für den Zugriff auf das CMS.
 * Sie verwendet die Environment-Variablen für die sichere Speicherung der Zugangsdaten.
 * 
 * Benötigte Environment-Variablen:
 * - REACT_APP_CONTENTFUL_SPACE_ID: Die Space ID des Contentful Workspaces
 * - REACT_APP_CONTENTFUL_ACCESS_TOKEN: Der Zugangs-Token für die API
 * 
 * Konfigurationsdetails:
 * - Environment: 'master' (Produktionsumgebung)
 * - Host: 'cdn.contentful.com' (Content Delivery API)
 * - Client: Verwendet das Contentful SDK für JavaScript
 * 
 * Fehlerbehandlung:
 * - Prüft auf vorhandene Zugangsdaten
 * - Wirft aussagekräftige Fehlermeldungen
 * - Logging der Konfiguration (ohne sensible Daten)
 */

import { createClient } from 'contentful';

const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

console.log('Contentful Config:', {
  spaceId,
  accessToken: accessToken ? 'exists' : 'missing',
  envVars: {
    hasSpaceId: !!process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    hasAccessToken: !!process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
  }
});

if (!spaceId || !accessToken) {
  throw new Error(
    'Contentful space ID and access token are required. ' +
    `Got: spaceId=${spaceId}, accessToken=${accessToken ? 'exists' : 'missing'}`
  );
}

const client = createClient({
  space: spaceId,
  accessToken: accessToken,
  host: 'cdn.contentful.com',
  environment: 'master'
});

export default client;
