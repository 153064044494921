import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import client from '../config/contentful';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Datenschutz = () => {
  const [datenschutzContent, setDatenschutzContent] = useState(null);
  const [error, setError] = useState(null);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-3" style={{ whiteSpace: 'pre-line' }}>
          {children}
        </p>
      ),
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="mt-4 mb-3">{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="mt-3 mb-2">{children}</h3>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="mb-3">{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="mb-1">{children}</li>,
    }
  };

  useEffect(() => {
    const fetchDatenschutz = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'datenschutz2',
          limit: 1
        });

        if (response.items.length > 0) {
          setDatenschutzContent(response.items[0].fields);
        } else {
          setError('Keine Datenschutzerklärung gefunden');
        }
      } catch (error) {
        console.error('Error fetching Datenschutz data:', error);
        setError('Fehler beim Laden der Datenschutzerklärung');
      }
    };

    fetchDatenschutz();
  }, []);

  if (error) {
    return (
      <Container className="py-4">
        <h1 className="mb-4">Datenschutzerklärung</h1>
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  if (!datenschutzContent) {
    return <Container className="py-4"><p>Laden...</p></Container>;
  }

  return (
    <Container className="py-4">
      <div className="datenschutz-content">
        {datenschutzContent.datenschutztext && documentToReactComponents(datenschutzContent.datenschutztext, options)}
      </div>
    </Container>
  );
};

export default Datenschutz;
