import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaFileDownload, FaFilePdf } from 'react-icons/fa';
import client from '../config/contentful';
import './Downloads.css';

/**
 * Downloads-Komponente zeigt eine Liste von Dokumenten zum Download an.
 * Die Dokumente werden dynamisch aus Contentful geladen und in einem
 * Kartenformat dargestellt.
 */
const Downloads = () => {
  // State für die Download-Dokumente
  const [downloads, setDownloads] = useState([]);

  // Lädt die Dokumente beim ersten Rendern
  useEffect(() => {
    const fetchDownloads = async () => {
      try {
        // Hole alle Einträge vom Typ 'download' aus Contentful
        // include: 2 stellt sicher, dass auch die verlinkten Assets (PDFs) geladen werden
        const response = await client.getEntries({
          content_type: 'download',
          order: 'sys.createdAt',
          include: 2
        });
        setDownloads(response.items);
      } catch (error) {
        console.error('Error fetching downloads:', error);
      }
    };

    fetchDownloads();
  }, []);

  /**
   * Berechnet die Dateigröße in KB aus den Bytes
   * @param {number} bytes - Dateigröße in Bytes
   * @returns {number} Dateigröße in KB (gerundet)
   */
  const calculateFileSizeInKB = (bytes) => Math.round(bytes / 1024);

  /**
   * Rendert eine einzelne Dokumentenkarte
   * @param {Object} download - Dokument-Objekt aus Contentful
   */
  const renderDownloadCard = (download) => (
    <Col md={4} className="mb-4" key={download.sys.id}>
      <Card className="h-100 shadow-sm">
        <Card.Body>
          <div className="d-flex align-items-center mb-3">
            <FaFilePdf className="text-danger me-2" size={24} />
            <h5 className="mb-0">{download.fields.dokumentenname}</h5>
          </div>
          <Card.Text>
            {download.fields.dokumentenbeschreibung}
          </Card.Text>
          {download.fields.dokument && (
            <div className="d-flex justify-content-between align-items-center">
              <small className="text-muted">
                {calculateFileSizeInKB(download.fields.dokument.fields.file.details.size)} KB
              </small>
              <a 
                href={`https:${download.fields.dokument.fields.file.url}`}
                className="btn btn-outline-success" 
                target="_blank" 
                rel="noopener noreferrer"
                download={download.fields.dokumentenname}
              >
                <FaFileDownload className="me-2" />
                Download
              </a>
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Container className="my-4">
      <h2 className="mb-4">Downloads</h2>

      <section>
        <h3 className="mb-4">Vereinsdokumente</h3>
        <Row>
          {downloads.map(renderDownloadCard)}
        </Row>
      </section>
    </Container>
  );
};

export default Downloads;
