/**
 * Sponsors Component
 * 
 * Diese Komponente zeigt eine Übersicht aller Sponsoren des Vereins an.
 * Die Daten werden aus Contentful geladen und in einem responsiven Grid dargestellt.
 * 
 * Contentful Struktur:
 * - Content Type: 'sponsoren'
 * - Felder:
 *   - name: Name des Sponsors
 *   - logo: Bild-Asset des Sponsor-Logos
 *   - link: URL zur Website des Sponsors (optional)
 */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import client from '../config/contentful';
import './Sponsors.css';

// Hilfsfunktion zum Korrigieren der Bild-URL
const fixImageUrl = (url) => {
  if (!url) return '';
  if (url.startsWith('//')) return `https:${url}`;
  return url;
};

const Sponsors = () => {
  // State für die Liste der Sponsoren
  const [sponsors, setSponsors] = useState([]);
  const [error, setError] = useState(null);

  // Lade Sponsoren beim ersten Render
  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        // Hole Sponsoren von Contentful
        const { items } = await client.getEntries({
          content_type: 'sponsoren'
        });
        
        console.log('Rohe Sponsoren-Daten:', items);
        
        // Validiere die Daten
        const validSponsors = items.filter(sponsor => {
          const isValid = sponsor.fields && 
            sponsor.fields.logo && 
            sponsor.fields.logo.fields && 
            sponsor.fields.logo.fields.file &&
            sponsor.fields.logo.fields.file.url;
            
          if (!isValid) {
            console.warn('Ungültiger Sponsor:', sponsor);
          }
          
          return isValid;
        });
        
        console.log('Validierte Sponsoren:', validSponsors);
        setSponsors(validSponsors);
        setError(null);
      } catch (error) {
        console.error('Error fetching sponsors:', error);
        setError('Fehler beim Laden der Sponsoren');
      }
    };

    fetchSponsors();
  }, []); // Leeres Array bedeutet: Nur einmal beim ersten Render ausführen

  // Debug-Ausgabe
  useEffect(() => {
    console.log('Sponsors State:', sponsors);
  }, [sponsors]);

  // Wenn ein Fehler aufgetreten ist, zeige eine Fehlermeldung
  if (error) {
    console.warn('Sponsors error:', error);
    return null;
  }

  // Wenn keine Sponsoren vorhanden sind, zeige nichts an
  if (!sponsors?.length) {
    console.warn('Keine Sponsoren gefunden');
    return null;
  }

  return (
    <section className="sponsors-section mt-5 mb-4">
      <h2 className="text-center mb-4">Unsere Sponsoren</h2>
      {/* Responsive Grid mit Bootstrap Row/Col System */}
      <Row className="justify-content-center align-items-center">
        {/* Iteriere über alle Sponsoren */}
        {sponsors.map(({ sys, fields }) => {
          // Logo-URL korrigieren
          const logoUrl = fixImageUrl(fields.logo?.fields?.file?.url);

          console.log('Verarbeite Sponsor:', {
            id: sys.id,
            name: fields.name,
            logoUrl,
            link: fields.link
          });

          if (!logoUrl) {
            console.warn('Kein Logo-URL für Sponsor:', sys.id);
            return null;
          }

          // Erstelle das Bild-Element
          const logoImage = (
            <img
              key={`img-${sys.id}`}
              src={logoUrl}
              alt={fields.name || 'Sponsor'}
              className="sponsor-logo img-fluid"
              onError={(e) => {
                console.error('Fehler beim Laden des Logos:', {
                  url: logoUrl,
                  sponsor: fields.name,
                  error: e.message
                });
              }}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          );

          // Wenn ein Link vorhanden ist, wickle das Bild in einen Link
          const content = fields.link ? (
            <a
              key={`link-${sys.id}`}
              href={fields.link}
              target="_blank"
              rel="noopener noreferrer"
              className="sponsor-link d-block text-center"
            >
              {logoImage}
            </a>
          ) : (
            <div key={`div-${sys.id}`} className="text-center">
              {logoImage}
            </div>
          );

          return (
            <Col 
              key={sys.id} 
              xs={6} 
              sm={4} 
              md={3} 
              lg={2} 
              className="sponsor-col mb-4"
              style={{ minHeight: '100px' }}
            >
              {content}
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default Sponsors;
