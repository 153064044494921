import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFileAlt, FaUniversity } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import client from '../config/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './Kontakt.css';

// Standardwerte für EmailJS (werden durch config.json überschrieben)
let emailConfig = {
  serviceId: 'service_9t35xdm',
  templateId: 'template_d8017z9',
  publicKey: 'FgQSnlGnPOY5XF-PC',
  toEmail: 'tusjagersfreude@gmail.com'
};

// Lade die Konfiguration aus config.json
fetch('/config.json')
  .then(response => response.json())
  .then(config => {
    if (config.emailjs) {
      emailConfig = {
        serviceId: config.emailjs.serviceId || emailConfig.serviceId,
        templateId: config.emailjs.templateId || emailConfig.templateId,
        publicKey: config.emailjs.publicKey || emailConfig.publicKey,
        toEmail: config.emailjs.toEmail || emailConfig.toEmail
      };
      // EmailJS initialisieren
      emailjs.init(emailConfig.publicKey);
    }
  })
  .catch(error => {
    console.warn('Could not load config.json:', error);
    // Initialisiere EmailJS mit den Standardwerten
    emailjs.init(emailConfig.publicKey);
  });

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node) => {
      const textContent = node.content
        .map(content => content.value)
        .join('');

      return (
        <p className="mb-0">
          {textContent.split('\n').map((text, i, array) => (
            <React.Fragment key={i}>
              {text}
              {i < array.length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      );
    }
  }
};

const initialFormData = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: ''
};

const Kontakt = () => {
  const [formState, setFormState] = useState({
    data: initialFormData,
    validated: false,
    sending: false,
    showSuccess: false,
    showError: false
  });
  const [kontaktInfo, setKontaktInfo] = useState(null);

  useEffect(() => {
    const fetchKontaktInfo = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'kontaktinformationen',
          limit: 1
        });
        
        if (response.items.length > 0) {
          setKontaktInfo(response.items[0].fields);
        }
      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchKontaktInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      data: { ...prev.data, [name]: value }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setFormState(prev => ({ ...prev, validated: true }));
      return;
    }

    setFormState(prev => ({
      ...prev,
      validated: true,
      sending: true,
      showSuccess: false,
      showError: false
    }));

    try {
      await emailjs.send(
        emailConfig.serviceId,
        emailConfig.templateId,
        {
          to_email: emailConfig.toEmail,
          from_name: formState.data.name,
          from_email: formState.data.email,
          phone: formState.data.phone,
          subject: formState.data.subject,
          message: formState.data.message
        },
        emailConfig.publicKey
      );

      setFormState(prev => ({
        ...prev,
        data: initialFormData,
        validated: false,
        showSuccess: true,
        sending: false
      }));
    } catch (error) {
      console.error('Error sending email:', error);
      setFormState(prev => ({
        ...prev,
        showError: true,
        sending: false
      }));
    }
  };

  const renderContactInfo = () => (
    <Card className="shadow-sm h-100">
      <Card.Body>
        <h3 className="mb-4">Kontaktinformationen</h3>
        
        {kontaktInfo && (
          <>
            <div className="d-flex align-items-start mb-3">
              <FaMapMarkerAlt className="text-success me-3 mt-1" size={24} />
              <div className="text-start">
                <h5 className="mb-0">Adresse</h5>
                {documentToReactComponents(kontaktInfo.adresse, renderOptions)}
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <FaPhone className="text-success me-3" size={24} />
              <div>
                <h5 className="mb-0">Telefon</h5>
                <p className="mb-0">{kontaktInfo.telefon}</p>
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <FaEnvelope className="text-success me-3" size={24} />
              <div>
                <h5 className="mb-0">E-Mail</h5>
                <p className="mb-0">{kontaktInfo.mail}</p>
              </div>
            </div>
            
            <div className="d-flex align-items-start mb-3">
              <FaUniversity className="text-success me-3 mt-1" size={24} />
              <div className="text-start">
                <h5 className="mb-0">Bankverbindung</h5>
                {documentToReactComponents(kontaktInfo.bankverbindung, renderOptions)}
              </div>
            </div>

            <div className="d-flex align-items-start mb-3">
              <FaFileAlt className="text-success me-3 mt-1" size={24} />
              <div className="text-start">
                <h5 className="mb-0">Vereinsinformationen</h5>
                {documentToReactComponents(kontaktInfo.vereinsinformationen, renderOptions)}
              </div>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );

  const renderContactForm = () => (
    <Card className="shadow-sm">
      <Card.Body>
        <h3 className="mb-4">Kontaktformular</h3>
        <Form noValidate validated={formState.validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
                value={formState.data.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie Ihren Namen ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="E-Mail"
                value={formState.data.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Telefon (optional)"
                value={formState.data.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Control
                required
                type="text"
                name="subject"
                placeholder="Betreff"
                value={formState.data.subject}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie einen Betreff ein.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Control
              required
              as="textarea"
              name="message"
              rows={5}
              placeholder="Ihre Nachricht"
              value={formState.data.message}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Bitte geben Sie eine Nachricht ein.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-grid">
            <Button 
              type="submit" 
              variant="success"
              disabled={formState.sending}
            >
              {formState.sending ? 'Wird gesendet...' : 'Nachricht senden'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="my-4">
      <h2 className="mb-4">Kontakt</h2>
      
      {formState.showSuccess && (
        <Alert variant="success" onClose={() => setFormState(prev => ({ ...prev, showSuccess: false }))} dismissible>
          Ihre Nachricht wurde erfolgreich gesendet! Wir werden uns so schnell wie möglich bei Ihnen melden.
        </Alert>
      )}
      
      {formState.showError && (
        <Alert variant="danger" onClose={() => setFormState(prev => ({ ...prev, showError: false }))} dismissible>
          Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </Alert>
      )}

      <Row>
        <Col lg={5} className="mb-4">
          {renderContactInfo()}
        </Col>
        <Col lg={7}>
          {renderContactForm()}
        </Col>
      </Row>
    </Container>
  );
};

export default Kontakt;
